import { Link } from "gatsby";
import React, { useState } from "react"

import * as SignUpStyles from "./signup.module.scss"

const AlmostDone = (props) => {
  
  const [showFields, setShowFields] = useState(false);
  const [showResellerField, setToggleResellerFields] = useState(false);
  const [showPromoField, setTogglePromoFields] = useState(false);

  const toggleFields = () => {
    setShowFields(s => !s);
  }

  const toggleResellerFields = () => {
    setToggleResellerFields(s => !s);
  }

  const togglePromoFields = () => {
    setTogglePromoFields(s => !s);
  }

  return ( 
    <section className={SignUpStyles.signUp}>
      <div className={SignUpStyles.innerDiv}>
        <div className="component">
          <h1>Almost Done</h1>
          <div className={SignUpStyles.subText}>{"The last bit of information to get your trial up and running"}</div>
          <div className={SignUpStyles.emailForm}>
            <div className={SignUpStyles.formDiv}>
              <div className={SignUpStyles.inputWrap}>        
                <input type="text" name="company-name" placeholder="Company Name" className="text-input" {...props.bindCompanyName} />
              </div>
              <div className={SignUpStyles.inputWrap}>                  
                <input type="text" name="venue-name" placeholder="Venue Name" className="text-input" {...props.bindVenueName} />
              </div>
              <div className={SignUpStyles.inputWrap}>                  
                <input type="text" name="number-of-venues" placeholder="Number of Venues" className="text-input" {...props.bindNumberOfVenues} />
              </div>
              <div className={SignUpStyles.inputWrap}>                  
                <input type="text" name="business-address" placeholder="Business Address" className="text-input" {...props.bindBusinessAddress} />
              </div>
              {showResellerField && <>
              <div className={SignUpStyles.inputWrap}>                  
                <input type="text" name="reseller-code" placeholder="Reseller Code" className="text-input" {...props.bindResellersCode} />
              </div>
              </>
              }
              <div className={SignUpStyles.toggleFields} onClick={toggleResellerFields}>
              {showResellerField ? `- Hide Reseller Code` : `+ Add Reseller Code`}
              </div>
              {showPromoField && <>
              <div className={SignUpStyles.inputWrap}>                  
                <input type="text" name="promo-code" placeholder="Promo Code" className="text-input" {...props.bindPromoCode} />
              </div>
              </>
              }
              <div className={SignUpStyles.toggleFields} onClick={togglePromoFields}>
              {showPromoField ? `- Hide Promo Code` : `+ Add Promo Code`}
              </div>
              <div className={SignUpStyles.inputWrap}>        
                <input type="submit" value="Complete Sign Up &rarr;" role="button" onClick={props.nextStep} /> 
                <div className={SignUpStyles.prevButton} role="button" onClick={props.prevStep} >
                  &larr; Go Back
                </div>
              </div>
            </div>
          
          </div>
          <div className={SignUpStyles.disclaimer}>
            By creating a loaded account agree to the <Link to="/end-user-licence-agreement">End User Licence Agreement</Link> and <Link to="/privacy-policy">Privacy Policy.</Link>
          </div>
        </div> 
      </div> 
    </section> 
  )
}

export default AlmostDone;