import React from "react"
import Base from "../../components/templates/Base"

import AlmostDone from "../../components/SignUp/AlmostDone";

const SignUpPage = () => {
  return (
    <Base>
      <AlmostDone />
    </Base>
  );
};

export default SignUpPage;